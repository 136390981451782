<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Providers</h4>

        <form action="" @submit.prevent="addProvider">
          <div class="form-group">
            <div class="row">
              <div class="col-md-6">
                <label for="add-provider">Add new provider</label>
                <input type="text" class="form-control" id="add-provider" placeholder="Enter provider name here ... " required
                  v-model="form.name"
                >
              </div>
            </div>
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </form>

      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Providers List</h4>

        <table class="table">
          <thead>
            <tr>
              <th>Provider name</th>
              <th>Date created</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
              <tr v-for="provider in providers">
                <td>{{provider.name}}</td>
                <td>{{provider.createdAt}}</td>
                <td>
                  <ul class="list-inline table-action m-0">
                    <li class="list-inline-item">
                      <a href="javascript:void(0);" class="action-icon" @click="showEditProviderModal(provider)">
                        <i class="mdi mdi-square-edit-outline"></i
                        > </a>
                    </li>
                    <li class="list-inline-item text-danger">
                      <a href="javascript:void(0);" class="action-icon" @click="removeProvider(provider.id)">
                        <i class="mdi mdi-delete"></i
                        > </a>
                    </li>
                  </ul>
                </td>
              </tr>
          </tbody>
        </table>

      </div>
    </div>
    <b-modal id="edit-provider-modal" @ok="updateProviderTapped" title="Edit provider name">
        <div class="form-group">
          <label>Provider name</label>
          <input class="form-control" type="text" placeholder="Enter name here" v-model="editForm.name">
        </div>
    </b-modal>
  </div>
</template>

<script>
import appConfig from "../../../../../app.config.json";
import {confirm} from "../../../../utils/functions";

/**
 * Starter component
 */
export default {
  head() {
    return {
      title: `${this.title}`,
    };
  },
  data() {
    return {
      title: "Starter",
      form: {
        name: ''
      },
      editForm: {
        id: '',
        name: ''
      }
    };
  },
  computed: {
    providers() {
      return this.$store.getters.GET_PROVIDERS;
    }
  },
  methods: {
    removeProvider(id){
        confirm('Delete provider', () => {
          this.$store.dispatch('removeProvider', id);
        })
    },
    addProvider(){
        this.$store.dispatch('addProvider', {
          'name': this.form.name
        }).then(() => {
          this.form.name = '';
        })
    },
    updateProviderTapped(){
      if(this.editForm.name === ''){
        this.$store.dispatch('error', {message: 'Provider name field is required' , showSwal: true})
        return;
      }
      this.$store.dispatch('updateProvider', {
        id: this.editForm.id,
        name : this.editForm.name
      }).then(() => {
        this.editForm.name = '';
      })
    },
    showEditProviderModal(provider){

      this.editForm.id = provider.id;
      this.editForm.name = provider.name;
      this.$bvModal.show('edit-provider-modal');
    }
  },
  page: {
    title: "RedCabs",
    meta: [{ name: "description", content: appConfig.description }],
  },
  created() {
    this.$store.dispatch('fetchProviders')
  }
};
</script>

